import React, { useEffect, useState,useRef } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField, // Import TextField for the search input
} from "@mui/material";
import axios from "axios";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { toast } from 'react-toastify';

function ListData() {
  // const mbillUrl = "billing1.geekdialer.com";
  const mbillUrl = "mbilling.tacolabs.shop";
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState(""); // State variable for search query

  const hasMounted = useRef(false);

  useEffect(() => {
    // Check if the effect has already run
    if (!hasMounted.current) {
      // Run your code here
      getUsers();

      // Set the ref to true to indicate that the effect has run
      hasMounted.current = true;
    }
  }, []);

  async function getUsers() {
    toast.info("Fetching users...", { position: toast.POSITION.TOP_RIGHT });
    await axios.get(`https://${mbillUrl}/magnusAPI/users.php`).then(function (response) {
        setUsers(response.data);
      });
  }

  const  deleteUser = async (username) => {
    await axios.delete(`https://${mbillUrl}/magnusAPI/process.php/delete/user/${username}`).then(function (response) {
        toast.success("User Deleted Successfully...", { position: toast.POSITION.TOP_RIGHT });
        getUsers();
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Filter the user list based on the search query
  const filteredUsers = users.filter((user) =>
    user.username.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <Paper sx={{ width: "90%", overflow: "hidden", justifyContent: "center", alignItems: "center", margin: "0 5%" }}>
        <h4>List User</h4>
        {/* Add the search input */}
        <TextField
          label="Search by username"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          variant="outlined"
          fullWidth
          sx={{ marginBottom: 2 }}
        />
        <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>username</TableCell>
                <TableCell>password</TableCell>
                <TableCell>credit</TableCell>
                <TableCell>calllimit</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(filteredUsers) && filteredUsers.length > 0 ? (
                filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user, key) => (
                    <TableRow key={key} hover>
                      <TableCell><Link to={`user/${user.username}/edit`} style={{ marginRight: "10px" }}> {user.id} </Link></TableCell>
                      <TableCell>{user.username}</TableCell>
                      <TableCell>{user.password}</TableCell>
                      <TableCell>{user.credit}</TableCell>
                      <TableCell>{user.calllimit}</TableCell>
                      <TableCell>
                        <Button variant="contained" onClick={() => deleteUser(user.username)}> Delete </Button>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>No matching users found.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}

export default ListData;
